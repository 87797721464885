import React, { useEffect, type FC, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { getUserByToken } from '../redux/reducers/user';
import { type RouteLayoutInterface } from '../interfaces';
import { NavigationRail, Header, Footer } from '../components';
import { useWindowSize } from '../hooks/useWindowSize';
import { LiquidationModalContent, LiquidationModalHeader } from '../globalModals/LiquidationModal.tsx/LiquidationModal';
import { GlobalDialogContext } from '../context/GlobalDialogContext/GlobalDialogContextProvider';
import { socket } from '../web/socket';
import { getAccounts as getAllAccounts } from '../redux/reducers/accounts';
import { getAccounts, getInvestedBalance, getTotalBalance, setUniqueId } from '../redux/reducers/cfd';
import { getOrders } from '../redux/reducers/orders';
import { selectIsAlertMessage, selectIsRunningAdvertisement } from '../redux/selectors/alertMessage';

import styles from '../App.module.css';

export const PrivateLayout: FC<RouteLayoutInterface> = ({ isUserAuth, children }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const location = useLocation();
  const { showDialog } = useContext(GlobalDialogContext);
  const { _id: userId } = useAppSelector(state => state.user);
  const { activeAccount, orderStatus: orderStatusFromStore } = useAppSelector(state => state.cfd);
  const isRunningAdvertisementShown = (isRunningAdvertisement && location.pathname.includes('/trade'))
  const isAlertMessageShown = (isAlertMessage && location.pathname.includes('/trade'));

  const isMobile = screenWidth < 600;

  const triggerLiquidationModal = (): any => {
    showDialog({
      headerContent: (<LiquidationModalHeader />),
      footerContent: (<LiquidationModalContent />)
    });
  }

  useEffect(() => {
    if (!isUserAuth) {
      navigate('/login');
      return;
    }

    dispatch(getUserByToken())
  }, [isUserAuth]);

  useEffect(() => {
    if (userId != null) {
      socket.on(`onLiquidateAccount&${userId}`, async () => {
        const orderStatus = orderStatusFromStore ?? 'processing,pending';

        triggerLiquidationModal();

        Promise.allSettled([
          dispatch(getOrders({ accountId: activeAccount._id, userId, status: orderStatus })),
          dispatch(getAllAccounts(userId)),
          dispatch(getAccounts(userId)),
          activeAccount._id.length > 0 && dispatch(getInvestedBalance({ userId, accountId: activeAccount._id })),
          dispatch(getTotalBalance(userId)),
          dispatch(setUniqueId())
        ])
      });
    }

    return () => {
      if (userId !== undefined) {
        socket.off(`onLiquidateAccount&${userId}`);
      }
    };
  }, [userId, activeAccount._id, location.pathname])

  return (
    <div className={styles.pageContainer}>
      <NavigationRail />
      <Header openMenu={ openMenu } setOpenMenu={ setOpenMenu }/>
      <div
        className={styles.contentContainer}
        style={{
          padding: isMobile
            ? ((isAlertMessageShown || isRunningAdvertisementShown) ? '66px 0' : '72px 0 64px 0')
            : '72px 0 64px 80px'
        }}
      >
        {children}
      </div>
      <Footer setOpenMenu={ setOpenMenu }/>
    </div>
  );
}
