import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthWrapper } from './auth/AuthWrapper';
import { SnackbarWrapper, RunningTickerComponent, RunningAdvertisementLineComponent } from './components';
import { defineDefaultLangCodeCorrect, getLanguages } from './redux/reducers/language';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { selectLanguage } from './redux/selectors/language';
import { getAlertMessage, getRunningAdvertisement } from './redux/reducers/alertMessage';
import { selectIsAlertMessage, selectIsRunningAdvertisement } from './redux/selectors/alertMessage'
import { useWindowSize } from './hooks/useWindowSize';

import styles from './App.module.css';

function App (): JSX.Element {
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();
  const activeLanguage = useAppSelector(selectLanguage);
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);

  const isMobile = screenWidth <= 599;

  useEffect(() => {
    Promise.all([
      dispatch(defineDefaultLangCodeCorrect()),
      dispatch(getLanguages()),
      dispatch(getAlertMessage()),
      dispatch(getRunningAdvertisement())
    ]);
  }, []);

  return (
    <BrowserRouter>
      <SnackbarWrapper>
        <div className={ styles.appContainer }>
          { isAlertMessage && <RunningTickerComponent /> }
          { (isMobile && isRunningAdvertisement) && <RunningAdvertisementLineComponent /> }
          <div
            className={`${styles.app} ${activeLanguage === 'ar' ? styles.arabicFont : styles.defaultFont}`}
          >
            <AuthWrapper key={activeLanguage}/>
          </div>
          { (!isMobile && isRunningAdvertisement) && <RunningAdvertisementLineComponent /> }
        </div>
      </SnackbarWrapper>
    </BrowserRouter>
  );
}

export default App;
